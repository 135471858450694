<template>
  <div>
    <headbox title="消息中心" bgColor="#fff" isborder="true"></headbox>
    <van-tabs>
      <van-tab title="系统消息">
        <!-- 系统消息 -->
        <div
          class="system_msg"
          v-for="(items, index) in adminmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_2.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="行情消息">
        <!-- 行情消息 -->
        <div
          class="quotes_msg"
          v-for="(items, index) in hangqingmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_1.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="交易消息">
        <!-- 交易信息 -->
        <div
          class="transaction_msg"
          v-for="(items, index) in trapmsg"
          :key="items.id"
          @click="msgdetials(items.id)"
        >
          <div class="flex msg_list rel">
            <div class="iconbox">
              <img src="@/assets/images/message_3.png" alt="" />
            </div>
            <div class="">
              <b class="time">{{ items.enableTime | formatDate_ }}</b>
              <p class="titlebox">{{ items.title }}</p>
              <p class="details">{{ items.summary | delfont }}...</p>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as msglist from "@/utils/api";
import { formatDate_ } from "@/utils/filters";
export default {
  data() {
    return {
      // 交易信息
      trapmsg: [],
      // 行情信息
      hangqingmsg: [],
      // 系统消息
      adminmsg: []
    };
  },
  filters: {
    formatDate_,
    delfont: function(val) {
      return val.substr(0, 30) + "...";
    }
  },
  components: {
    headbox
  },
  methods: {
    msgdetials(id) {
      this.$router.push({ name: "messageDeatils", query: { id: id } });
    }
  },
  mounted() {
    msglist.gettrade().then(res => {
      this.adminmsg = res.notice.content;
      this.trapmsg = res.trade.content;
      this.hangqingmsg = res.custom.content;
    });
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-tabs__nav--line {
  background: #f7f7f7;
  padding-bottom: 0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: auto;
}
::v-deep .van-tab {
  padding: 25px 0;
  font-size: 32px;
}
::v-deep .van-tab--active .van-tab__text--ellipsis {
  color: #ffb606;
}
::v-deep .van-tabs__line {
  bottom: 0;
  background-color: #ffb606;
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 32px;
  span {
    padding: 25px 30px;
  }
  .active {
    position: relative;
    color: #ffb606;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 65px;
  margin: 2px 30px 0;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
</style>
