var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "消息中心", bgColor: "#fff", isborder: "true" }
      }),
      _c(
        "van-tabs",
        [
          _c(
            "van-tab",
            { attrs: { title: "系统消息" } },
            _vm._l(_vm.adminmsg, function(items, index) {
              return _c(
                "div",
                {
                  key: items.id,
                  staticClass: "system_msg",
                  on: {
                    click: function($event) {
                      return _vm.msgdetials(items.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flex msg_list rel" }, [
                    _c("div", { staticClass: "iconbox" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/message_2.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", {}, [
                      _c("b", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(items.enableTime)))
                      ]),
                      _c("p", { staticClass: "titlebox" }, [
                        _vm._v(_vm._s(items.title))
                      ]),
                      _c("p", { staticClass: "details" }, [
                        _vm._v(_vm._s(items.summary) + "...")
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "van-tab",
            { attrs: { title: "行情消息" } },
            _vm._l(_vm.hangqingmsg, function(items, index) {
              return _c(
                "div",
                {
                  key: items.id,
                  staticClass: "quotes_msg",
                  on: {
                    click: function($event) {
                      return _vm.msgdetials(items.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flex msg_list rel" }, [
                    _c("div", { staticClass: "iconbox" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/message_1.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", {}, [
                      _c("b", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(items.enableTime)))
                      ]),
                      _c("p", { staticClass: "titlebox" }, [
                        _vm._v(_vm._s(items.title))
                      ]),
                      _c("p", { staticClass: "details" }, [
                        _vm._v(_vm._s(items.summary) + "...")
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "van-tab",
            { attrs: { title: "交易消息" } },
            _vm._l(_vm.trapmsg, function(items, index) {
              return _c(
                "div",
                {
                  key: items.id,
                  staticClass: "transaction_msg",
                  on: {
                    click: function($event) {
                      return _vm.msgdetials(items.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flex msg_list rel" }, [
                    _c("div", { staticClass: "iconbox" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/message_3.png"),
                          alt: ""
                        }
                      })
                    ]),
                    _c("div", {}, [
                      _c("b", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(items.enableTime)))
                      ]),
                      _c("p", { staticClass: "titlebox" }, [
                        _vm._v(_vm._s(items.title))
                      ]),
                      _c("p", { staticClass: "details" }, [
                        _vm._v(_vm._s(_vm._f("delfont")(items.summary)) + "...")
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }